<script>
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
import { BPagination } from "bootstrap-vue";
import { convertDateTime, convertDate } from "@/_helpers/index";
import Treeselect from "@riophae/vue-treeselect";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { format, parseISO, getHours } from "date-fns";
import "vue2-datepicker/index.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    BPagination,
    DatePicker,
    Treeselect,
    Multiselect,
  },
  props: {
    dataLimit: {
      type: Number,
      default: 25,
    },
    withPrint: {
      type: Boolean,
      default: true,
    },
    enabledEditSort: {
      type: Boolean,
      default: false,
      required: false,
    },
    noAction: {
      type: Boolean,
      default: false,
      required: false,
    },
    withShow: {
      type: Boolean,
      default: true,
      required: false,
    },
    withEdit: {
      type: Boolean,
      default: true,
      required: false,
    },
    withDelete: {
      type: Boolean,
      default: true,
      required: false,
    },
    withAdd: {
      type: Boolean,
      default: true,
    },
    withFixed: {
      type: Boolean,
      default: false,
    },
    withFilterName: {
      type: Boolean,
      default: false,
    },
    withFilterClothCategory: {
      type: Boolean,
      default: false,
    },
    withFilterColorMultiple: {
      type: Boolean,
      default: false,
    },
    withFilterCreatedComplete: {
      type: Boolean,
      default: false,
    },
    withFilterSizeMultiple: {
      type: Boolean,
      default: false,
    },
    withFilterDefault: {
      type: Boolean,
      default: true,
    },
    withFilterRole: {
      type: Boolean,
      default: false,
    },
    withFilterCategory: {
      type: Boolean,
      default: false,
    },
    withFilterCategoryOrder: {
      type: Boolean,
      default: false,
    },
    withFilterOrder: {
      type: Boolean,
      default: false,
    },
    withFilterCategoryOrderDown: {
      type: Boolean,
      default: false,
    },
    paramsCategory: {
      type: String,
      default: "customer_category",
    },
    withFilterCreatedBy: {
      type: Boolean,
      default: false,
    },
    withFilterCreatedByUrl: {
      type: Boolean,
      default: false,
    },
    withFilterDate: {
      type: Boolean,
      default: false,
    },
    withFilterColor: {
      type: Boolean,
      default: false,
    },
    withFilterSize: {
      type: Boolean,
      default: false,
    },
    dataUrl: {
      type: String,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    defaultParams: {
      type: [Array, Object],
      required: false,
    },
    dataColumns: {
      type: [Array, Object],
      required: true,
    },
    withFormAdd: {
      type: Boolean,
      required: true,
    },
    withRevenueOrder: {
      type: Boolean,
      required: false,
    },
    withAmountPurchase: {
      type: Boolean,
      required: false,
    },
    formUrl: {
      type: String,
      required: true,
    },
    customDetailUrl: {
      type: String,
      required: false,
    },
    withHistory: {
      type: String,
      required: false,
    },
    merge: {
      type: Object,
      required: false,
    },
    // action: {
    //   type: Boolean,
    //   required: false,
    //   default: true,
    // },
    selectOptions: [Object, Array],
    categoryOption: [Object, Array],
    selectColors: [Object, Array],
    datas: [Object, Array],
    titleFilter: {
      type: String,
      default: "Filter Berdasarkan Nama",
    },
    filterWith: {
      type: String,
      default: "Filter Berdasarkan Nama",
    },
  },
  data() {
    return {
      isLoading: false,
      showFieldSort: false,
      modelSort: 0,
      paginationData: {
        meta: {
          current_page: 1,
          per_page: this.dataKey == "user" ? 26 : this.dataLimit,
          total: 0,
        },
      },
      sortColumn: null,
      sortOrder: null,
      filterText: "",
      filterColor: "",
      filterSize: "",
      filterClothCategory: "",
      filterRole: "",
      filterCategory: "",
      filterCategoryOrder: "",
      filterCreatedBy: "",
      filterCreatedByUrl: "",
      filterDate: null,
      startDate: null,
      createdBy: [],
      endDate: null,
      selectCreated: [],
      multipleColor: [],
      multipleSize: [],
      filterOrder: "",
      createdUrl: process.env.VUE_APP_API_BASE_URL + "users",
      getAccount: JSON.parse(localStorage.getItem("account_data")),
    };
  },
  watch: {
    dataUrl: {
      immediate: true,
      handler() {
        this.getDataTable(this.constructUrl());
      },
    },
  },
  computed: {
    displayedData() {
      let filteredData = this.paginationData.data;

      if (this.filterCreatedBy) {
        filteredData = filteredData.filter(
          (e) => e.created_by.name === this.filterCreatedBy
        );
      }

      if (this.getAccount.role === "owner") {
        return filteredData.filter((e) => e.role !== "owner");
      } else if (this.getAccount.role === "manager") {
        return filteredData.filter((e) => e.role !== "manager");
      } else {
        return filteredData;
      }
    },
    startIndex() {
      if (this.displayedData.length === 0) {
        return 0;
      }
      return this.paginationData.meta.current_page > 0
        ? (this.paginationData.meta.current_page - 1) *
            this.paginationData.meta.per_page +
            1
        : 0;
    },
    endIndex() {
      return Math.min(
        this.paginationData.meta.current_page *
          this.paginationData.meta.per_page,
        this.paginationData.meta.total
      );
    },
    paginateTotal() {
      const a = this.paginationData.data.filter((e) => e.role == "owner");
      const b = this.paginationData.data.filter((e) => e.role == "manager");
      if (this.getAccount.role == "owner") {
        return this.paginationData.meta.total - a.length;
      } else if (this.getAccount.role == "manager") {
        return this.paginationData.meta.total - (a.length + b.length);
      } else {
        return this.paginationData.meta.total;
      }
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    checkRoles() {
      const getAccount = localStorage.getItem("account_data");
      const parse = JSON.parse(getAccount);
      const cek = parse.role === "owner" || parse.role === "manager";
      return cek ? true : false;
    },
    filteredMenuItems() {
      try {
        return this.menuItems.filter((menuItem) => {
          const requiredRoles = menuItem.meta
            ? menuItem.meta.requiredAllowedRole
            : [];
          if (
            requiredRoles.includes(
              this.$store.getters["account/accountData"].role
            )
          ) {
            if (menuItem.subItems) {
              menuItem.subItems = menuItem.subItems.filter((subItem) => {
                const subItemRequiredRoles = subItem.meta
                  ? subItem.meta.requiredAllowedRole
                  : [];
                return subItemRequiredRoles.includes(
                  this.$store.getters["account/accountData"].role
                );
              });
            }
            return true;
          }
          return false;
        });
      } catch (error) {
        return [];
      }
    },
  },
  mounted() {
    if (this.dataKey == "user") this.getCreatedBy();
  },
  methods: {
    capitalizeFirstLetter(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    formattedDate(value) {
      return convertDateTime(value);
    },
    async getDataTable(url) {
      try {
        this.isLoading = true;
        await axios.get(url).then((response) => {
          this.paginationData = response.data;
          this.isLoading = false;
        });
      } catch (error) {
        console.error(error);
      }
    },
    handlePageChange(newPage) {
      this.paginationData.meta.current_page = newPage;
      this.filterText.length > 0
        ? this.getDataTable(this.constructUrlFilter())
        : this.getDataTable(this.constructUrl());
    },
    constructUrl() {
      let params = "";
      if (this.defaultParams !== undefined && this.defaultParams.length > 0) {
        params = "&";
        this.defaultParams.forEach((data) => {
          params += data.name + "=" + data.value;
        });
      }
      let url = `${this.dataUrl}?limit=${this.paginationData.meta.per_page}&page=${this.paginationData.meta.current_page}${params}`;

      if (this.withHistory) {
        url += `&cloth_id=${this.withHistory}`;
      }
      return url;
    },
    constructUrlSort() {
      return `${this.dataUrl}?order=${this.sortColumn}&ascending=${this.sortOrder}`;
    },
    constructUrlFilter() {
      let queryParams = [
        `limit=${this.paginationData.meta.per_page}`,
        `page=${this.paginationData.meta.current_page}`,
      ];

      if (this.filterText) {
        queryParams.push(
          this.filterText.includes("@")
            ? `email=${this.filterText}`
            : isNaN(this.filterText)
            ? `${this.filterWith}=${this.filterText}`
            : `phone_number=${this.filterText}`
        );
      }

      if (this.startDate && this.endDate) {
        queryParams.push(
          `start_date=${format(this.startDate, "yyyy-MM-dd")}`,
          `end_date=${format(this.endDate, "yyyy-MM-dd")}`
          // `start_date=${this.startDate.toLocaleDateString("id-ID").split("T")[0]}`,
          // `end_date=${this.endDate.toISOString().split("T")[0]}`
        );
      }

      if (this.filterClothCategory) {
        queryParams.push(`cloth_category_id=${this.filterClothCategory}`);
      }

      if (Array.isArray(this.selectCreated) && this.selectCreated.length > 0) {
        queryParams.push(`created_by=${this.selectCreated.value.id}`);
      }

      if (Array.isArray(this.multipleColor) && this.multipleColor.length > 0) {
        const color = this.multipleColor;
        queryParams.push(`color_ids=${color.join(",")}`);
      }

      if (Array.isArray(this.multipleSize) && this.multipleSize.length > 0) {
        const size = this.multipleSize;
        queryParams.push(`size_ids=${size.join(",")}`);
      }

      [
        "filterRole",
        "filterCategoryOrder",
        "filterCreatedByUrl",
        "filterColor",
        "filterSize",
        "filterOrder",
      ].forEach((filter) => {
        if (this[filter]) {
          queryParams.push(
            `${filter.replace("filter", "").toLowerCase()}=${this[filter]}`
          );
        }
      });

      if (this.filterCategoryOrder) {
        queryParams.push(`${this.paramsCategory}=${this.filterCategoryOrder}`);
      }

      let params = "";
      if (this.defaultParams !== undefined && this.defaultParams.length > 0) {
        params = "&";
        this.defaultParams.forEach((data) => {
          params += data.name + "=" + data.value;
        });
      }

      return `${this.dataUrl}?${queryParams.join("&")}${params}`;
    },
    calculateRowNumber(index) {
      return this.isLoading
        ? (this.paginationData.meta.current_page - 1) *
            this.paginationData.meta.per_page +
            index
        : (this.paginationData.meta.current_page - 1) *
            this.paginationData.meta.per_page +
            index +
            1;
    },
    handleSortChange({ column, prop, order }) {
      if (order === "ascending") {
        this.sortColumn = prop;
        this.sortOrder = 1;
      } else if (order === "descending") {
        this.sortColumn = prop;
        this.sortOrder = 0;
      }
      this.getDataTable(this.constructUrlSort());
    },
    applyFilter() {
      this.isLoading = true;
      this.paginationData.meta.current_page = 1;
      this.getDataTable(this.constructUrlFilter());
      this.isLoading = false;
    },
    clearFilter() {
      this.isLoading = true;
      this.filterText = "";
      this.filterRole = "";
      this.filterCategoryOrder = "";
      this.filterDate = "";
      this.filterCreatedBy = "";
      this.filterCreatedByUrl = "";
      this.filterColor = "";
      this.filterSize = "";
      this.selectCreated = [];
      this.filterClothCategory = "";
      this.filterOrder = "";
      this.multipleColor = [];
      this.multipleSize = [];
      this.startDate = null;
      this.endDate = null;
      this.paginationData.meta.current_page = 1;
      this.getDataTable(this.constructUrl());
      this.isLoading = false;
    },
    formatLabel(key) {
      return key
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());
    },
    formattedOnlyDate(value) {
      return convertDate(value);
    },
    getLastPathUrl() {
      const fullPath = this.$route.path;
      return fullPath;
    },
    checkReferral(value, type) {
      let el = null;
      let path = ''
      if (value[type] > 0) {
        switch (value.referral) {
          case 'Order':
            path = 'penjualan'
            break;
          case 'Purchase':
            path = 'pembelian'
            break;
          default:
            path = 'produk'
            break;
        }
        el =
            '<a href="#/'+ path +'/' +
            value.referral_id +
            '" target="_blank"> ' +
            (value[type] > 0 ? value[type] : "-") +
            "</a>";
      } else {
        el = "-";
      }

      return el;
    },
    handleDirectUserEl(val, pathName = "pengguna") {
      let el = null;
      if (val.created_by) {
        el = `<a href="#/${pathName}/${val.created_by.id}">${val.created_by.name}</a>`;
      } else {
        el = "-";
      }
      return el;
    },
    handleDirectSupplierEl(val, pathName = "pengguna") {
      let el = null;
      if (val.supplier) {
        el = `<a href="#/${pathName}/${val.supplier.id}">${val.supplier.name}</a>`;
      } else {
        el = "-";
      }
      return el;
    },
    handleRouteDetail(value, customUrl = null) {
      if (customUrl) {
        return this.$router.push(customUrl + value.id);
      } else {
        return this.$router.push(this.getLastPathUrl() + "/" + value.id);
      }
    },
    handleRouteEdit(value) {
      this.$router.push(this.getLastPathUrl() + "/" + value.id + "/edit");
    },
    handleRouteSpesificUser(value) {
      this.$router.push("/pengguna/" + value.created_by.id);
      // document.location.href = '/pengguna/' + value.created_by.id
      // window.location.href = `http://localhost:8080/#/pengguna/${value.created_by.id}`;
    },
    handleDelete(rowData) {
      this.$swal
        .fire({
          title: "Apakah Kamu Yakin ?",
          text: "Data tidak dapat dikembalikan!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Ya, Hapus!",
        })
        .then((result) => {
          if (result.value) {
            this.$http
              .delete(`${this.dataUrl + "/" + rowData.id}`)
              .then(() => {
                this.$store.dispatch(
                  "notification/success",
                  "Data berhasil dihapus"
                );
                this.getDataTable(this.constructUrl());
              })
              .catch((error) => {
                this.isLoading = false;
                if (process.env.NODE_ENV !== "production") {
                  console.error(error);
                  if (error.response.status == 404) {
                    this.$router.push("/404-notfound");
                  }
                }
                this.$store.dispatch("notification/error", error);
              });
          }
        });
    },
    getValue(obj, path) {
      const keys = path.split(".");
      let value = obj;

      for (const key of keys) {
        if (value === null || value === undefined) {
          return "-";
        }
        value = value[key];
      }

      return value !== null && value !== undefined ? value : "-";
    },
    linkAddData() {
      if (this.formUrl.length !== 0 && this.withFormAdd) {
        this.$router.push(this.formUrl);
      }
    },
    updateFilterDate(value) {
      console.log('test')
      if (Array.isArray(value) && value.length === 2) {
        this.startDate = value[0];
        this.endDate = value[1];
      } else {
        this.startDate = null;
        this.endDate = null;
      }
    },
    checkColor(id) {
      const color = this.selectOptions.find((color) => color.id === id);
      return color ? color.code : null;
    },
    checkCreatedBy(id) {
      const created = this.createdBy.find((data) => data.id === id);
      return created ? created.role : "-";
    },
    checkClothId(id) {
      const cloth = this.datas.id === id;

      return cloth ? this.datas.cloth_category.name : "-";
    },
    enabledSort() {
      this.showFieldSort = !this.showFieldSort ? true : false;
      this.getDataTable(this.constructUrl());
    },
    async submitSort(rowData) {
      console.log(rowData);
      this.$http
        .patch(`${this.dataUrl + "/" + rowData.id}`, {
          sort: rowData.sort,
        })
        .then(() => {
          this.$store.dispatch("notification/success", "Data berhasil diubah");
        })
        .catch((error) => {
          this.isLoading = false;
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
            if (error.response.status == 404) {
              this.$router.push("/404-notfound");
            }
          }
          this.$store.dispatch("notification/error", error);
        });
    },
    async getCreatedBy() {
      try {
        this.isLoading = true;
        await axios.get(this.createdUrl).then((response) => {
          const dataCreated = [
            ...new Set(
              response.data.data.map((data) => {
                return data;
              })
            ),
          ];

          this.createdBy = dataCreated.map((user) => ({
            role: user.role,
            id: user.id,
          }));
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getCreatedBySearch(url) {
      try {
        await axios.get(url).then((response) => {
          this.selectCreated = response.data.data;
        });
      } catch (error) {
        console.error(error);
      }
    },

    constructUrlCreatedBy(query) {
      return `${this.createdUrl}?name=${query}`;
    },

    applyFilterCreatedby(query) {
      if (query.length > 2) {
        this.getCreatedBySearch(this.constructUrlCreatedBy(query));
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="m-3">
      <b-form @submit.prevent="applyFilter" v-if="withFilterName">
        <b-row>
          <b-row>
            <b-col class="col-md-8 align-items-center">
              <b-row>
                <b-col class="my-2" v-if="withFilterDefault">
                  <el-input
                    :placeholder="titleFilter"
                    v-model="filterText"
                    clearable
                  >
                  </el-input>
                </b-col>
                <b-col class="my-2 col-md-6" v-if="withFilterClothCategory">
                  <el-select
                    class="w-100"
                    v-model="filterClothCategory"
                    placeholder="Pilih Kategori Pakaian"
                    clearable
                  >
                    <el-option
                      v-for="category in categoryOption"
                      :label="category.value"
                      :key="category.id"
                      :value="category.id"
                    ></el-option>
                  </el-select>
                </b-col>
                <b-col class="my-2 col-md-6" v-if="withFilterRole">
                  <el-select
                    class="w-100"
                    v-model="filterRole"
                    placeholder="Pilih Role"
                    clearable
                  >
                    <el-option
                      :label="capitalizeFirstLetter(option.value)"
                      v-for="(option, index) in selectOptions"
                      :key="index"
                      :value="option.value"
                      v-if="option.value != 'owner'"
                    ></el-option>
                  </el-select>
                </b-col>
                <b-col class="my-2 col-md-6" v-if="withFilterCategoryOrder">
                  <el-select
                    class="w-100"
                    v-model="filterCategoryOrder"
                    placeholder="Pilih Kategori Pelanggan"
                    clearable
                  >
                    <el-option
                      v-for="option in selectOptions"
                      :label="capitalizeFirstLetter(option.value)"
                      :key="option.id"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                </b-col>
                <b-col class="my-2" v-if="withFilterDate">
                  <el-date-picker
                    style="width: 100%;"
                    v-model="filterDate"
                    @input="updateFilterDate"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="Tanggal Awal"
                    end-placeholder="Tanggal Akhir"
                    clearable
                  >
                  </el-date-picker>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" class="my-2" v-if="withFilterOrder">
                  <el-input
                    placeholder="Filter No. Order"
                    v-model="filterOrder"
                    clearable
                  >
                  </el-input>
                </b-col>
                <b-col class="my-2 col-md-6" v-if="withFilterCreatedByUrl">
                  <el-input
                    placeholder="Dibuat Oleh"
                    v-model="filterCreatedByUrl"
                    clearable
                  >
                  </el-input>
                </b-col>
                <b-col class="my-2 col-md-6" v-if="withFilterColor">
                  <el-input
                    placeholder="Filter Warna"
                    v-model="filterColor"
                    clearable
                  >
                  </el-input>
                </b-col>
                <b-col class="my-2 col-md-6" v-if="withFilterCreatedComplete">
                  <multiselect
                    v-model="selectCreated.value"
                    :options="selectCreated"
                    :internal-search="true"
                    :searchable="true"
                    track-by="id"
                    label="name"
                    :placeholder="`Dibuat Oleh`"
                    @search-change="applyFilterCreatedby"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                  </multiselect>
                </b-col>
                <b-col class="my-2 col-md-6" v-if="withFilterColorMultiple">
                  <treeselect
                    v-model="multipleColor"
                    :options="selectColors"
                    :auto-load-root-options="false"
                    :multiple="true"
                    placeholder="Pilih Warna"
                  />
                </b-col>
                <b-col class="my-2 col-md-6" v-if="withFilterSizeMultiple">
                  <treeselect
                    v-model="multipleSize"
                    :options="selectOptions"
                    :auto-load-root-options="false"
                    :multiple="true"
                    placeholder="Pilih Ukuran"
                  />
                </b-col>
                <b-col class="my-2 col-md-6" v-if="withFilterSize">
                  <el-select
                    class="w-100"
                    v-model="filterSize"
                    placeholder="Pilih Ukuran"
                    clearable
                  >
                    <el-option
                      v-for="size in selectOptions"
                      :label="size.value"
                      :key="size.id"
                      :value="size.value"
                    ></el-option>
                  </el-select>
                </b-col>
                <b-col class="my-2 col-md-6" v-if="withFilterCategoryOrderDown">
                  <el-select
                    class="w-100"
                    v-model="filterCategoryOrder"
                    placeholder="Kategori Pelanggan"
                    clearable
                  >
                    <el-option
                      v-for="option in selectOptions"
                      :label="capitalizeFirstLetter(option.value)"
                      :key="option.id"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="col-md-3 my-2">
              <el-button type="primary" native-type="submit">Filter</el-button>
              <el-button type="primary" plain @click="clearFilter"
                >Clear</el-button
              >
            </b-col>
            <!-- <b-col
              class="my-2 text-end col-md-1 align-items-center"
              v-if="checkRoles && withAdd"
            > -->
            <b-col
              class="my-2 text-end col-md-1 align-items-center"
              v-if="withAdd"
            >
              <b-row>
                <b-col>
                  <b-btn
                    variant="success"
                    @click="linkAddData()"
                    class="mr-auto text-white"
                    style="width: 4em"
                    ><i class="uil-plus"></i
                  ></b-btn>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-row>
      </b-form>
    </div>
    <div class="container-fluid">
      <div v-if="isLoading" class="text-center">
        <b-progress
          :value="100"
          :max="100"
          height="15px"
          variant="primary"
          animated
        ></b-progress>
      </div>
      <el-table
        v-else
        stripe
        @sort-change="handleSortChange"
        :data="displayedData"
        style="width: 100%"
        border
      >
        <template slot="empty">
          <p>Data Tidak Ada / Tidak di Temukan</p>
        </template>
        <el-table-column
          prop="no"
          width="130"
          align="center"
          v-if="showFieldSort"
        >
          <div slot="header" class="table-light">
            <div class="d-flex justify-content-evenly">
              <b-button size="sm" variant="danger" @click="enabledSort()">
                <i class="fa fa-times"></i> Hide
              </b-button>
              <!-- <b-button size="sm" variant="success" @click="">
                <i class="fa fa-check"></i> Submit
              </b-button> -->
            </div>
          </div>
          <template slot-scope="scope">
            <div class="d-flex justify-content-evenly">
              <b-button
                size="sm"
                variant="success"
                @click="submitSort(scope.row)"
              >
                <i class="fa fa-check"></i>
              </b-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="no" width="100" align="center">
          <div
            slot="header"
            class="table-light"
            v-if="enabledEditSort"
            @click="enabledSort()"
            style="cursor: pointer;"
          >
            #
          </div>
          <div slot="header" class="table-light" v-else>
            #
          </div>
          <template slot-scope="scope">
            <template v-if="enabledEditSort">
              <span v-if="!showFieldSort">{{ scope.row.sort }}</span>
              <div class="d-flex" v-else>
                <b-form-input
                  type="number"
                  v-model="scope.row.sort"
                  class="text-center"
                  placeholder="Masukkan Urutan"
                ></b-form-input>
              </div>
            </template>
            <span v-else>{{ calculateRowNumber(scope.$index) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(column, key) in dataColumns"
          :sortable="column.sortable"
          :width="column.width"
          :key="key"
          :prop="column.prop"
          :align="column.center ? 'center' : column.right ? 'right' : 'left'"
          :fixed="column.fixed ? true : false"
        >
          <span slot="header" class="table-light">
            {{ formatLabel(column.label) }}
          </span>
          <template slot-scope="scope">
            <!-- <template v-if="column.badge">
              <span class="badge-custom bg-soft-dark text-uppercase">
                {{
                  column.prop
                    .split(".")
                    .reduce((obj, key) => obj[key], scope.row) || "-"
                }}
              </span>
            </template> -->

            <template v-if="column.badgeOrder">
              <span
                class="p-1-custom badge rounded-pill fw-bold fs-customFont"
                :class="{
                  'bg-success':
                    scope.row.status &&
                    scope.row.status.toLowerCase() === 'lunas',
                  'bg-warning':
                    scope.row.status &&
                    scope.row.status.toLowerCase() === 'pending',
                  'bg-danger':
                    scope.row.status &&
                    scope.row.status.toLowerCase() !== 'lunas' &&
                    scope.row.status.toLowerCase() !== 'pending',
                }"
                >{{
                  column.prop
                    .split(".")
                    .reduce((obj, key) => obj[key], scope.row) || "-"
                }}</span
              >
            </template>
            <template v-else-if="column.badgePurchase">
              <span
                class="p-1-custom badge rounded-pill fw-bold fs-customFont"
                :class="{
                  'bg-success':
                    scope.row.status &&
                    scope.row.status.toLowerCase() === 'accepted',
                  'bg-warning':
                    scope.row.status &&
                    scope.row.status.toLowerCase() === 'ordered',
                  'bg-danger':
                    scope.row.status &&
                    scope.row.status.toLowerCase() !== 'ordered' &&
                    scope.row.status.toLowerCase() !== 'accepted',
                }"
                >{{
                  column.prop
                    .split(".")
                    .reduce((obj, key) => obj[key], scope.row) || "-"
                }}</span
              >
            </template>

            <template v-else-if="column.created_history">
              <span>
                {{ checkCreatedBy(scope.row.created_by) }}
              </span>
            </template>
            <template v-else-if="column.purchase_date">
              <span>
                {{
                  scope.row.purchase_date !== null
                    ? formattedOnlyDate(scope.row.purchase_date)
                    : "-"
                }}
              </span>
            </template>
            <template v-else-if="column.updated_history">
              <span class="">
                {{ checkCreatedBy(scope.row.updated_by) }}
              </span>
            </template>
            <template v-else-if="column.subtotal">
              <div>Rp.{{ scope.row.sub_total.toLocaleString("id-ID") }}</div>
            </template>
            <template v-else-if="column.cloth_id">
              <span class="">
                {{ checkClothId(scope.row.cloth_id) }}
              </span>
            </template>
            <template v-else-if="column.dp">
              <div>
                Rp.{{ scope.row.amount_down_payment.toLocaleString("id-ID") }}
              </div>
            </template>
            <template v-else-if="column.user">
              <div v-html="handleDirectUserEl(scope.row)"></div>
            </template>
            <template v-else-if="column.supplier">
              <div v-html="handleDirectSupplierEl(scope.row, 'supplier')"></div>
            </template>
            <template v-else-if="column.status">
              <span
                v-if="
                  column.prop
                    .split('.')
                    .reduce((obj, key) => obj[key], scope.row) === true ||
                    column.prop
                      .split('.')
                      .reduce((obj, key) => obj[key], scope.row) === 1 ||
                    column.prop
                      .split('.')
                      .reduce((obj, key) => obj[key], scope.row) === '1'
                "
                class="p-1-custom badge rounded-pill fw-bold fs-customFont bg-success px-2"
              >
                Aktif
              </span>
              <span
                v-else
                class="p-1-custom badge rounded-pill fw-bold fs-customFont bg-danger"
                >Tidak Aktif</span
              >
            </template>
            <template v-else-if="column.stockin">
              <div v-html="checkReferral(scope.row, 'stock_in')"></div>
            </template>
            <template v-else-if="column.stockout">
              <div v-html="checkReferral(scope.row, 'stock_out')"></div>
            </template>
            <template v-else-if="column.total">
              <div>Rp.{{ scope.row.total.toLocaleString("id-ID") }}</div>
            </template>
            <template v-else-if="column.totalOfPayment">
              <div>
                Rp.
                {{
                  (
                    scope.row.grand_total
                  ).toLocaleString("id-ID")
                }}
              </div>
            </template>
            <template v-else-if="column.color">
              <div
                v-if="scope.row[column.prop]"
                class="avatar-sm mx-auto rounded-circle my-2"
                style="box-shadow: 2px 2px 2px 0px #d9d9d9; border: 1px solid #d9d9d9;"
                :style="{
                  backgroundColor: scope.row[column.prop].includes('#')
                    ? scope.row[column.prop]
                    : `#${scope.row[column.prop]}`,
                }"
              ></div>
              <div v-else>-</div>
            </template>
            <template v-else-if="column.colorTransaction">
              <div
                v-if="scope.row[column.prop]"
                class="d-flex gap-1 align-items-center"
              >
                <div
                  class="color-indicator"
                  :style="{
                    background: `#${
                      scope.row[column.prop].cloth_color !== null
                        ? scope.row[column.prop].cloth_color.color.code_hexa
                        : 'ffff'
                    }`,
                  }"
                ></div>
                <span class="">{{
                  scope.row[column.prop].cloth_color !== null
                    ? scope.row[column.prop].cloth_color.color.name
                    : "-"
                }}</span>
              </div>
            </template>
            <template v-else-if="column.date">
              {{ formattedDate(scope.row[column.prop]) || "-" }}
            </template>
            <template v-else-if="column.textdefault">
              {{
                column.prop
                  .split(".")
                  .reduce((obj, key) => obj[key], scope.row) || "-"
              }}
            </template>
            <template v-else-if="column.useBoolean">
              {{
                column.prop
                  .split(".")
                  .reduce((obj, key) => obj[key], scope.row) === 1 ||
                column.prop
                  .split(".")
                  .reduce((obj, key) => obj[key], scope.row) === "1"
                  ? "Ya"
                  : "Tidak" || "-"
              }}
            </template>
            <template v-else>
              <div
                v-if="column.badge"
                class="badge-custom bg-soft-dark text-uppercase fw-bold"
              >
                {{
                  column.prop
                    .split(".")
                    .reduce((obj, key) => obj[key], scope.row) || "-"
                }}
              </div>
              <p
                v-else
                :class="
                  scope.row[column.prop] || column.prop.includes('.')
                    ? 'text-capitalize m-0'
                    : 'text-center m-0'
                "
              >
                {{
                  column.prop.includes(".")
                    ? getValue(scope.row, column.prop)
                    : column.prop
                        .split(".")
                        .reduce((obj, key) => obj[key], scope.row) || "-"
                }}
              </p>
            </template>
          </template>
        </el-table-column>
        <!-- <el-table-column
          v-if="checkRoles && !noAction"
          :fixed="withFixed ? 'right' : null"
          align="center"
          width="165"
        > -->
        <el-table-column
          v-if="!noAction"
          :fixed="withFixed ? 'right' : null"
          align="center"
          width="165"
        >
          <span slot="header" class="table-light"> Aksi </span>
          <template slot-scope="scope">
            <template v-if="!noAction">
              <el-button
                v-if="withShow"
                type="primary"
                @click="handleRouteDetail(scope.row, customDetailUrl)"
                icon="el-icon-zoom-in"
                circle
              />
              <el-button
                v-if="withEdit"
                type="warning"
                @click="handleRouteEdit(scope.row)"
                icon="el-icon-edit"
                circle
              />
              <el-button
                v-if="withDelete && checkRoles"
                type="danger"
                @click="handleDelete(scope.row)"
                icon="el-icon-delete"
                circle
              />
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="row mt-3">
      <div class="col">
        Showing {{ isLoading ? "0" : startIndex }} to
        {{ isLoading ? "0" : endIndex }} of
        {{ isLoading ? "0" : paginateTotal }}
        entries
      </div>
      <div class="col" v-if="!isLoading && displayedData.length > 0">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination
              v-model="paginationData.meta.current_page"
              :total-rows="paginateTotal"
              :per-page="paginationData.meta.per_page"
              align="left"
              pills
              @change="handlePageChange"
            />
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end info-label" v-if="withRevenueOrder">
      <div class="label-omset bg-info fw-bold text-white">
        Omset: Rp. {{ this.paginationData.meta.omset ? this.paginationData.meta.omset.toLocaleString("id-ID") : 0 }},-
      </div>
      <div class="label-profit bg-primary fw-bold text-white">
        Profit: Rp. {{ this.paginationData.meta.profit ? this.paginationData.meta.profit.toLocaleString("id-ID") : 0 }},-
      </div>
    </div>
    <div class="d-flex justify-content-end info-label" v-if="withAmountPurchase">
      <div class="label-omset bg-info fw-bold text-white">
        QTY: {{ this.paginationData.meta.quantity ? this.paginationData.meta.quantity.toLocaleString("id-ID") : 0 }}
      </div>
      <div class="label-profit bg-primary fw-bold text-white">
        Pembelian: Rp. {{ this.paginationData.meta.purchase ? this.paginationData.meta.purchase.toLocaleString("id-ID") : 0 }},-
      </div>
    </div>
  </div>
</template>

<style scoped>
.el-table th.el-table__cell {
  background-color: #f5f6f8 !important;
}

.label-omset, .label-profit {
  padding: 10px 30px;
  border-radius: 10px;
  margin-right: 10px;
}

.info-label {
  position: fixed;
  bottom: 2%;
  z-index: 3;
  right: 3%;
}

.badge-custom {
  display: inline-block;
  padding: 0.4em 0.8em;
  font-size: 80%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
  width: 80px;
}
.fs-customFont {
  font-size: 12px;
}
.p-1-custom {
  padding: 0.4rem !important;
}

.custom-treeselect .vue-treeselect__input-container {
  height: 1000px !important;
}
</style>
